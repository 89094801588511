import React, { Component } from "react"
import Blocks from "./blocks/blocks"
import Layout from "../components/layout"
import UrlService from "../services/global/urlService"
class TemplateLandingPageWithoutQuot extends Component {

    render() {
        const {pageContext: context} = this.props;
        const {page} = context;
        const {media} = context;
        const titleSeo = page.titleSEO || '';
        const keywords = page.keywords ? page.keywords.split() : [];
        const {tracker, canonicalUrl} = page
        const canonical = UrlService.addSlashToEndOfUrl(canonicalUrl)
        return (
            <Layout titleSeo={titleSeo} keywordsSeo={keywords} description={page.description} tracker={tracker} canonical={canonical}>
                <div id="blocs">
                    <Blocks data={page.blocks} media={media}></Blocks>
                </div>
            </Layout>
        )
    }
}

export default TemplateLandingPageWithoutQuot
